import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  /* Heading, */ Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Stack,
  StatLabel,
} from '@chakra-ui/react';
import { AppTemplate } from '../../shared/ui/AppTemplate';
import { checkPhoneNumber, sendLeadToVakas } from '../../shared/api/auth';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../shared/enums/routes';
import { Typography, Checkbox } from 'antd';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { useCookies } from 'react-cookie';

function isNumeric(value: string) {
  if (value === '') {
    return true;
  }

  return /^-?\d+$/.test(value);
}

const getQueryParams = () => {
  const search = window.location.search;
  const searchParams = new URLSearchParams(search);
  const keys = Array.from(searchParams.keys());
  return keys.map(x => ({ [`${x}`]: searchParams.get(x) }));
};

export const Home = observer(() => {
  const [error, setError] = useState<boolean>();
  const [errorText, setErrorText] = useState<string | null>(null);
  //* ==================
  const [username, setUserName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  //* ==================
  const [friend_name, setFriend_Name] = useState<string>('');
  const [friend_phone, setFriend_Phone] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [anonym, setAnonym] = useState<boolean>(false);
  /* const [limit, setLimit] = useState<number>(0); */
  const queryParams = getQueryParams();
  const [qookies] = useCookies(['roistat_visit']);

  React.useEffect(() => {
    const firebaseConfig = {
      apiKey: 'AIzaSyAODnA4EfNRBeGgA4BNoEb3PYJ5TFcgAlY',
      authDomain: 'missis-laser.firebaseapp.com',
      projectId: 'missis-laser',
      storageBucket: 'missis-laser.appspot.com',
      messagingSenderId: '514174044584',
      appId: '1:514174044584:web:3a7576b39c7a543c6ab59d',
      measurementId: 'G-QTNN569ZLV',
    };

    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    logEvent(analytics, 'rozygryshvk');
  }, []);

  //* ==================
  const history = useHistory();

  const checkPhone = async (phone: string) => {
    return await checkPhoneNumber({
      phone,
    });
  };

  /* const checkRegCountFn = async () => {
    return await checkRegCount();
  }; */

  /* const checkRegCountData = useAsync(async () => {
    return await checkRegCount();
  }, []); */

  /* useEffect(() => {
    if (checkRegCountData.value?.data >= 300) {
      setError(true);
      setErrorText('Достигнут лимит в 300 участников.');
    }
    setLimit(checkRegCountData.value?.data);
  }, [checkRegCountData?.value?.data]); */

  const onSubmit = async () => {
    const visitCount = await checkPhone(friend_phone);
    /* const regCount = await checkRegCountFn(); */
    /* if (regCount.data >= 300) {
      setError(true);
      setErrorText('Достигнут лимит в 300 участников.');
      setLimit(regCount.data);
      return;
    } */
    if (visitCount.data.count > 0) {
      setError(true);
      setErrorText('Указанный Вами клиент уже посещал нашу клинику');
      return;
    } else {
      try {
        await sendLeadToVakas({
          name: friend_name,
          phone: '+7' + friend_phone,
          city,
          friend_name: username,
          friend_phone: '+7' + phone,
          roistat: qookies.roistat_visit,
          anonym: anonym ? 'АНОНИМНАЯ ЗАЯВКА' : 'не анонимно',
          utm_source: window.location.hostname,
          ...queryParams,
        }).then(async ({ data }) => {
          history.push(Routes.Success);
        });
      } catch (error) {
        console.log(error);
        history.push(Routes.Error);
      }
    }
  };

  return (
    <AppTemplate
      headerTitle='Тест драйв'
      headerBody={
        <StatLabel style={{ textAlign: 'center', fontSize: 15 }}>
          🩷Примите участие в акции "Подари другому" и получите много подарков! <br /> <br />
          1️⃣ Выбираете близких людей, которые еще не были клиентами нашей клиники и указываете их данные для отправки
          подарка. <br />
          Им поступит от вас 4 подарка на выбор: <br /> <br />
          Купон повышенного номинала на 7000 рублей <br />
          Акция "Бикини + Подмышки" за 990 Р <br />
          Акция "Бикини + Подмышки + Голени" за 1990 Р <br />
          Акция "Все тело" за 2990 Р <br /> <br />
          2️⃣ Ваш близкий человек выбирает интересующий его подарок. <br />
          И когда воспользуется им. Вам поступит сообщение о начислении подарка в размере 1000 рублей. <br />
          Чем больше подарков вы отправите, тем больше получите сами! <br /> <br />
          Дарите добро и получайте его в ответ вместе с Миссис Лазер! <br />
          Не упускайте шанс воспользоваться одной из самых выгодных акций! <br />
        </StatLabel>
      }
      //headerBody={`Акция для новых клиентов только в Январе!\nЛазерная эпиляция зон Бикини + Подмышки за 990 руб вместо 2300 руб! Пока есть возможность - оставляйте заявку и приходите на процедуру! Ждём Вас!`}
    >
      <Box
        p={0}
        overflowY='auto'
        flexGrow={1}
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Stack spacing='2rem'>
          <Stack spacing='1rem'>
            <Typography.Title level={5} style={{ textAlign: 'center' }}>
              Ваши контактные данные
            </Typography.Title>
            <InputGroup>
              <Input
                maxLength={30}
                /* isInvalid={error} */
                placeholder='Введите ваше имя'
                value={username}
                onChange={e => {
                  setUserName(e.target.value);
                  setError(false);
                }}
              />
            </InputGroup>
            <InputGroup>
              <InputLeftAddon children='+7' />
              <Input
                maxLength={10}
                type='tel'
                /* isInvalid={error} */
                placeholder='Введите ваш телефон'
                value={phone}
                onChange={event => {
                  isNumeric(event.target.value) && setPhone(event?.target.value);
                  setError(false);
                }}
              />
            </InputGroup>
          </Stack>
          <Stack spacing='1rem'>
            <Typography.Title level={5} style={{ textAlign: 'center' }}>
              Контактные данные вашей подруги/друга
            </Typography.Title>
            <InputGroup>
              <Input
                maxLength={30}
                /* isInvalid={error} */
                placeholder='Введите имя подруги'
                value={friend_name}
                onChange={e => {
                  setFriend_Name(e.target.value);
                  setError(false);
                }}
              />
            </InputGroup>
            <InputGroup>
              <InputLeftAddon children='+7' />
              <Input
                maxLength={10}
                type='tel'
                /* isInvalid={error} */
                placeholder='Введите телефон подруги'
                value={friend_phone}
                onChange={event => {
                  isNumeric(event.target.value) && setFriend_Phone(event?.target.value);
                  setError(false);
                }}
              />
            </InputGroup>
            <InputGroup>
              <Select
                value={city}
                onChange={event => {
                  setCity(event?.target.value);
                  setError(false);
                }}
                placeholder='Выберите город'
              >
                <option value='Москва'>Москва</option>
                <option value='Санкт-Петербург'>Санкт-Петербург</option>
                <option value='Казань'>Казань</option>
                <option value='Уфа'>Уфа</option>
                <option value='Тверь'>Тверь</option>
              </Select>
            </InputGroup>
          </Stack>

          {error && (
            <Stack spacing='1rem' style={{ marginTop: 12 }}>
              {errorText && (
                <Alert status='error'>
                  <AlertIcon />
                  {errorText}
                </Alert>
              )}
            </Stack>
          )}

          <Button
            /* disabled={limit >= 300} */
            onClick={() => {
              if (city === '' || friend_phone === '' || friend_name === '' || phone === '' || username === '') {
                setError(true);
                setErrorText(
                  city === ''
                    ? 'Вы не указали город'
                    : friend_phone === ''
                    ? 'Вы не указали номер подруги/друга'
                    : friend_name === ''
                    ? 'Вы не указали имя подруги/друга'
                    : phone === ''
                    ? 'Вы не указали свой номер'
                    : username === ''
                    ? 'Вы не указали своё имя'
                    : null
                );
              } else {
                onSubmit();
              }
            }}
            mt='24px'
            w='100%'
            h='60px'
            alignSelf='center'
            colorScheme='teal'
            variant='solid'
          >
            Хочу участвовать
            <br />в акции
          </Button>
          <Checkbox style={{ alignSelf: 'center' }} value={anonym} onClick={() => setAnonym(!anonym)}>
            Подарить анонимно
          </Checkbox>
          {/* <Typography style={{ textAlign: 'center', fontSize: 13, marginTop: '4px' }}>
            Осталось
            {` ${300 - limit} ${toCaseCount(['место', 'места', 'мест'], 300 - limit)}`}
          </Typography> */}
          <Typography style={{ textAlign: 'left', fontSize: 15 }}>
            📄Правила акции: <br />
            {/*  Акция действует до 31.03.2024 включительно. <br />
            Отправить подарки можно до 31.03.2024 <br /> 
            Воспользоваться подарком можно будет до конца апреля <br /> */}
            Информирование о бонусах поступает каждый понедельник не позднее 20:00 по мск. <br />
            Подарки можно отправить только тем, кто не был ранее клиентом нашей сети клиник. <br /> <br />
            Отправлять подарки могут только действующие клиенты. <br />
            Полученный бонус можно в полном объеме потратить на услуги сети клиник. <br />
            Количество подруг в акции не ограниченно.
            <br />
            <br />
            <br />
          </Typography>
        </Stack>
      </Box>
    </AppTemplate>
  );
});
